import React from 'react';
import { Box, Typography } from '@mui/material';
import Section from './Section';

const SeccionQueEs = () => {
  return (
    <Section title="¿Qué es Mi Rifa App?" hasBackground={false}>
      <Box sx={{ textAlign: 'justify' }}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <strong>Mi Rifa App</strong> es tu compañera ideal para organizar rifas de forma fácil, rápida y profesional. Con una plataforma intuitiva y amigable, te permite crear rifas en pocos pasos, gestionar la venta de boletos y llevar un control detallado del estado de cada boleto, ya sea reservado o pagado. Además, cuentas con gráficos y estadísticas en tiempo real para que puedas seguir el progreso de tus rifas, identificar patrones de ventas y ajustar tu estrategia para vender más.
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <strong>Mi Rifa App</strong> es perfecta tanto para pequeños emprendedores que quieren usar rifas para promocionar sus productos, como para organizaciones y grupos que buscan una manera eficiente y organizada de recaudar fondos.
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          ¿Qué hace especial a <strong>Mi Rifa App</strong>? Aquí te lo contamos:
        </Typography>
        <ul>
          <li><strong>Fácil de usar:</strong> Crear y gestionar tus rifas es súper sencillo gracias a una interfaz intuitiva. ¡Nada de complicaciones!</li>
          <li><strong>Control total:</strong> Desde ver qué boletos están disponibles, hasta marcar si han sido reservados o pagados. Tú tienes el control absoluto de cada boleto.</li>
          <li><strong>Notificaciones automáticas:</strong> No te preocupes por tener que avisar a cada comprador; la app se encarga de enviarles notificaciones cada vez que se realiza una venta. Además, avisa al ganador cuando se anuncia el resultado y manda recordatorios automáticos de cobro para asegurarse de que nadie se olvide de reclamar su premio.</li>
          <li><strong>Estadísticas claras y útiles:</strong> Visualiza el rendimiento de tus ventas con reportes y gráficos que te ayudan a entender mejor cómo va tu rifa, para que puedas tomar las mejores decisiones.</li>
          <li><strong>Gestión segura:</strong> Nos preocupamos por la seguridad. Toda la información de tus rifas y los datos de los participantes están protegidos con medidas de seguridad para que puedas operar con tranquilidad.</li>
        </ul>
        <Typography variant="body1" component="div">
          Con <strong>Mi Rifa App</strong>, organizar rifas es más sencillo que nunca. Es la herramienta ideal para quienes quieren gestionar sus rifas de manera profesional, sin complicaciones, y lograr el máximo éxito. ¡Anímate a usarla y descubre lo fácil que es llevar tus rifas al siguiente nivel!
        </Typography>
      </Box>

      {/* Contenedor responsive para los videos */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', gap: 2, mt: 3 }}>
        <Box sx={{ flex: 1 }}>
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/sXuAk6yX3a0?si=kx2apiPc9WH4tol_"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ maxWidth: '100%', borderRadius: '8px' }}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/07Ab2CC-wBM?si=McviJYWGrirEabcF"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ maxWidth: '100%', borderRadius: '8px' }}
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionQueEs;
